import './bootstrap'

// Vuejs
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { createNotivue } from 'notivue'
import route from './route'

import 'notivue/notification.css'
import 'notivue/notification-progress.css'
import 'notivue/animations.css'

import AppAuth from '../views/AppAuth.vue'

const pinia = createPinia()
createApp(AppAuth)
  .use(createNotivue({ 
    position: 'top-right', 
    duration: 5000,
    pauseOnHover: false,
  }))
  .use(pinia)
  .use(route)
  .mount('#app')
